.loading-modal {
  background: rgba(255, 255, 255, 0.95);
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 100;

  &--active {
    display: table;
  }

  &__body {
    color: #555;
    display: table-cell;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    vertical-align: middle;
  }

  &__icon {
    font-size: 12em;
  }

  &__message {
    &__header {
      display: inline-block;
      font-size: 2em;
      margin-top: 1em;
    }
    &__body {
      display: inline-block;
      font-size: 1.3em;
      line-height: 1.4em;
      padding: 0 20px;
      margin-top: 1em;

      //padding: 0 25%;
    }
  }
}

.glyphicon {
  &-spin--clockwise {
    animation: spin-clockwise 7s infinite linear;
  }
  &-spin--counterclockwise {
    animation: spin--counterclockwise 7s infinite linear;
  }
}

@keyframes spin-clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
@keyframes spin-counterclockwise {
  0% {
    transform: rotate(359deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
