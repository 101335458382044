 @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

 @import "loading";
body, input, textarea, select {
  font-family: 'Droid Sans',Helvetica,Arial,Lucida,sans-serif;
}
html {
  background: #00383e;
}

#app {
  padding-bottom: 3em;
}

 h1, .h1 {
  font-size: 3rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
 }

.navbar {
  border-top: solid 4px #93d6db;
  min-height: 84px;
  margin-bottom: 0;
}

.nva_logo {
  height: inherit;
  padding-bottom: 8px;
  padding-top: 8px;

  img {
    height: 60px;
    width: auto;
  }
}

.navbar-toggle {
  margin-top: 20px;
}
.navbar-default {
  .navbar-nav {
    @media (min-width: 768px) {
      margin: 15px -15px;
    }

    & > li > a {
      font-family: "Trebuchet MS",Tahoma,sans-serif; /**/
      font-size: 17px;
      font-weight: 400;
      color: rgb(0,74,81);
      // color: #004a51;
      text-decoration: none;
      text-transform: none;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

.banner {
    margin: 0 0 20px 0;
}
  .banner__image {
    border-bottom: solid 2px #93d6db;
    border-top: solid 1px #004b52;
    height: auto;
    width: 100%;
  }

  .banner__attribution {
    color: #d0f0e0;
    font-size: 12px;
    margin-top: -25px;
    margin-right: 10px;
    text-align: right;
    font-style: italic;
  }

.input-group {
  input.danger {
    color: red;
    background: #fdd;
  }
  input.success {
    color: green;
    background: #dfd;
  }
}
.question-description {
  font-weight: normal;
}

 #results {
   .row {
     margin-bottom: 3em;
   }

 }
   .result_explaination {
     &_header {
       margin-top: 0;
     }

     color: #555;

     &:hover {
       color: #000;
     }
   }

.tool-tip {
  color: #777;
  cursor: pointer;
  &:hover {
    color: #000;
  }
}

.tooltip {
  &__screen {
    cursor: pointer;
    display: none;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.8);
    z-index: 5;

    &.open {
      display: block;
    }
  }

  &__container {
    background: #fff;
    border: 1px solid #555;
    border-radius: .5em;
    margin: 0 auto;
    margin-top: 25%;
    margin-bottom: 2em;
    max-width: 90%;
    padding: 1.5em;
    width: 450px;
  }

  &__close_button {
    position: relative;
    right: -.5em;
    top: -.75em;
  }
}


.button {
  &--remove {
    &:hover {
      cursor: pointer;
    }
  }
}

// Bootstrap modifications
.text--grey {
  color: #999;
}

.no-col-pad {
  padding-left: 0;
  padding-right: 0;
}

fieldset {
  margin-bottom: 3em;
}

.table {
  ul {
    padding-left: 2em;
    border-left: 1px solid #ddd;
  }
  li {
    margin-bottom: .25em;
  }
}

.trademark {
  font-style: italic;

  &__superscript {
    font-size: .75em;
    position: inline;
    top: 0;
    vertical-align: super

  }

  &--header {
    .trademark__superscript{
      font-size: .5em;
    }
  }
}

footer, .footer {
  background: #00383e;
  color: #839496;
}

.newsletter {
  &__container {
    background: #004b52;
    padding: 15px;
  }

  &__signup__title {
    text-align: left;
    font-family: "bebas-neue",sans-serif;
    font-weight: 400;
    font-size: 36px;
    font-style: normal;
    text-transform: uppercase;
    color: #fff;
    line-height: 1;
  }

  &-input {
    border: solid 3px #7fc3cb;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-family: "Trebuchet MS",Tahoma,sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    padding: 11px 45px 13px;
    margin-top: 15px;

    &--email {
      background: #26666c;
      border: none;
      border-radius: 30px;
      display: inline-block;
      height: 53px;
      font-family: "Trebuchet MS",Tahoma,sans-serif;
      font-size: 15px;
      font-style: italic;
      font-weight: normal;
      text-decoration: none;
      text-transform: none;
      padding: 11px 45px 13px;
      width: 100%;

      &::placeholder {
        color: white;
      }
    }

    &--submit {
        background: #004b52;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
    }
  }
}


.footer-menu {
  margin-top: 10px;
}

.social-menu {
  text-align: center;

  .icon {
    font-size: 20px;
  }

}

.footer-menu, .social-menu {
  color: #fff;

  ul{
    padding-start: unset;
    -webkit-padding-start: unset;

    li {
      font-family: "Trebuchet MS", Tahoma, sans-serif !important;
      color: #4c4c4c;
      display: inline-block;
      font-size: 16px;
      line-height: 1.5;
      list-style: none;
      margin: 10px 20px 10px 0;
      padding: 0;
    }
  }
  a, a:visited {
    color: #fff;
  }
  a:active, a:hover {
    color: #8fd4d9;
    text-decoration: none;
  }
}

.copyright {
  font-family: "Trebuchet MS",Tahoma,sans-serif;
  font-size: 17px;
  line-height: 2;

  a, a:visited {
    color: #839496;
  }
  a:active, a:hover {
    color: #fff;
    text-decoration: none;
  }
}

